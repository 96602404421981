import React from "react";
import Section from "../../components/Section";
import { pageTitle } from "../../helpers/PageTitle";
import DietForm from "./DietForm";
import DietFormDetails from "./DietFormDetails";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GenerateDietReportSchema from "./constantAndUtilities";
import { generateDietReport } from "../../services/store/slices/dietSlice";

const Diet = () => {
  pageTitle("Diet");

  const [formSteps, setFormSteps] = React.useState(0);

  const dispatch = useDispatch();

  const dietForm = useForm({
    mode: "onChange",
    resolver: yupResolver(GenerateDietReportSchema),
  });

  const submitCallback = (data) => {
    setFormSteps((prev) => prev + 1);

    let heightInCms = data.height;

    if (data.heightType === "feet") heightInCms = data.height * 30.48;

    let heightCmToMtr = heightInCms * 0.01;

    const bmiValue = (data.weight / (heightCmToMtr * heightCmToMtr)).toFixed(2);

    let heightCmToInch = (heightInCms / 2.54).toFixed(2);

    let ibwValue;

    if (data.gender === "male") {
      ibwValue = 50 + 2.3 * (heightCmToInch - 60).toFixed(2);
    } else {
      ibwValue = 45.5 + 2.3 * (heightCmToInch - 60).toFixed(2);
    }

    const adjustedBodyWeight = (
      ibwValue +
      0.4 * (data.weight - ibwValue)
    ).toFixed(2);

    const dietInfo = {
      name: data.name,
      age: data.age,
      gender: data.gender,
      heightType: data.heightType,
      height: data.height,
      weight: data.weight,
      activity: data.activity,
      illness: data.illness,
      goal: data.goal,
      bmi: bmiValue,
      ibw: ibwValue,
      abw: adjustedBodyWeight,
    };

    dispatch(generateDietReport(dietInfo));
  };

  const getStepsComponent = () => {
    if (formSteps === 0)
      return <DietForm onSubmit={submitCallback} methods={dietForm} />;
    else if (formSteps === 1 || formSteps === 2)
      return <DietFormDetails steps={formSteps} />;
    else return null;
  };

  const getButtonActions = () => {
    if (formSteps === 2) return null;

    return (
      <div className="cs_diet_btn_actions">
        <div className="cs_diet_btn_back">
          <button
            type="submit"
            className="cs_btn cs_style_1"
            onClick={() => setFormSteps((prev) => prev > 0 ? prev - 1 : prev)}
          >
            <i>
              <img
                loading="lazy"
                src="/images/icons/arrow_white.svg"
                alt="Icon"
              />
              <img
                loading="lazy"
                src="/images/icons/arrow_white.svg"
                alt="Icon"
              />
            </i>
            <span>Back</span>
          </button>
        </div>
        <div className="cs_diet_btn_next">
          {formSteps === 0 && (
            <button
              type="submit"
              className="cs_btn cs_style_1"
              onClick={dietForm.handleSubmit((data) => submitCallback(data))}
            >
              <span>Next</span>
              <i>
                <img
                  loading="lazy"
                  src="/images/icons/arrow_white.svg"
                  alt="Icon"
                />
                <img
                  loading="lazy"
                  src="/images/icons/arrow_white.svg"
                  alt="Icon"
                />
              </i>
            </button>
          )}
          {formSteps === 1 && (
            <button
              type="submit"
              className="cs_btn cs_style_1"
              onClick={() => setFormSteps((prev) => prev + 1)}
            >
              <span>Generate Report</span>
              <i>
                <img
                  loading="lazy"
                  src="/images/icons/arrow_white.svg"
                  alt="Icon"
                />
                <img
                  loading="lazy"
                  src="/images/icons/arrow_white.svg"
                  alt="Icon"
                />
              </i>
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Section topMd={200} topLg={200} topXl={200} className="cs_diet_page_sec">
        <div className="container">
          <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12">
            <div className="cs_diet_page_container">
              {getStepsComponent()} {getButtonActions()}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Diet;
