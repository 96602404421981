import React from 'react';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import KidneyBreadcrumStyle from '../Breadcrumb/KidneyBreadcrumStyle';
import DownloadBannerStyle from '../Section/BannerSection/DownloadBannerSectionStyle';
import Spacing from '../Spacing';
import DownloadBlogSection from '../Section/BlogSection/DownloadBlogSection';
// import { Icon } from "@iconify/react";
// import Spacing from '../Spacing';


export default function Download() {
  pageTitle('Download');
  // const { t } = useTranslation(["common"]);
  // const { blogData } = commonConstants();

  const blogSectionDatasdownload = [
    {
      title: "Kidney Needs Flyer",
      subTitle:
        "Education and clinical tools for the prevention and management of kidney disease...",
      filename: "sample.pdf",
      pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
      socialShare: false,
      btnText: "Read",
      dowbtnText:"Download",
      btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
    {
      title: "Kidney Needs Brochure",
      subTitle:
        "Best affordable kidney medical health and wellness services in INDIA...",
        filename: "sample.pdf",
        pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
        socialShare: false,
        btnText: "Read",
        dowbtnText:"Download",
        btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
    {
      title: "Kidney Needs Coupons",
      subTitle:
        "Our research creates knowledge about and treatments for kidney needs that are among...",
        filename: "sample.pdf",
        pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
        socialShare: false,
        btnText: "Read",
        dowbtnText:"Download",
        btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
    {
      title: "Kidney Needs Form Medical",
      subTitle:
        "Know your health status up to date for you and your family, take an action today...",
        filename: "sample.pdf",
        pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
        socialShare: false,
        btnText: "Read",
        dowbtnText:"Download",
        btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
  ];

  const blogSectionDatasdownload2 = [
    {
      title: "Kidney Needs Form pharmacy",
      subTitle:
        "Education and clinical tools for the prevention and management of kidney disease...",
        filename: "sample.pdf",
        pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
        socialShare: false,
        btnText: "Read",
        dowbtnText:"Download",
        btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
    {
      title: "Kidney Needs Form Lab",
      subTitle:
        "Best affordable kidney medical health and wellness services in INDIA...",
        filename: "sample.pdf",
        pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
        socialShare: false,
        btnText: "Read",
        dowbtnText:"Download",
        btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
    {
      title: "Kidney Needs Programs",
      subTitle:
        "Our research creates knowledge about and treatments for kidney needs that are among...",
        filename: "sample.pdf",
        pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
        socialShare: false,
        btnText: "Read",
        dowbtnText:"Download",
        btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
    {
      title: "About Kidney Needs",
      subTitle:
        "Know your health status up to date for you and your family, take an action today...",
        filename: "sample.pdf",
        pdfurl: "pdfs/CircadianRhythmandCKD.pdf",
        socialShare: false,
        btnText: "Read",
        dowbtnText:"Download",
        btnArrowUrl:"/images/icons/Download_Arrow.svg"
    },
  ];

  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* <Spacing lg="600" md="120" /> */}
      <Section className="cs_banner_styles">
        <DownloadBannerStyle
          bgUrl="/images/download/Download-Page-banner.jpg"
          subHeadTitle="Download"
          customeThemeClass=""
        />
      </Section>

       <Spacing lg="600" md="100" /> 
       {/* part 1 start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DownloadBlogSection
          customeThemeClass={"bright-blogs"}
          sectionTitle=""
          sectionTitleUp=""
          data={blogSectionDatasdownload}
        />
      </Section>

{/* part 1 end */}

{/* part 2 start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <DownloadBlogSection
          customeThemeClass={"bright-blogs"}
          sectionTitle=""
          sectionTitleUp=""
          data={blogSectionDatasdownload2}
        />
      </Section>
    </>
  );
}