// Card.js
import React from 'react';
import { HashLink } from 'react-router-hash-link';
// import './Card.scss';

function Card({ data }) {
  return (

    <div className="card">
      {/* TODO: update to dynamic urls and add Ids in the respective faq sections */}
      <HashLink smooth to={data.url}>
        <img src={data.image} alt={data.title} className="card-image" />
        <div className="card-content">
          <h3 className="card-title">{data.title}</h3>
          <p className="card-text">{data.content}</p>
          {/* <div className="card-social">
          <div className='card-social-in'>
            <a href="#" target="_blank" rel="noopener noreferrer"> <Icon icon="fa-brands:instagram" /></a>
            <a href='#' target="_blank" rel="noopener noreferrer"><Icon icon="fa-brands:youtube" /></a>
            <a href='#' target="_blank" rel="noopener noreferrer"><Icon icon="fa-brands:linkedin-in" /></a>
            <a href='#' target="_blank" rel="noopener noreferrer"> <Icon icon="fa-brands:twitter" /></a>
          </div>
        </div> */}
        </div>
      </HashLink>
    </div>
  );
}

export default Card;
