import React from 'react';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import Portfolio from '../../Portfolio';

// export default function GallerySection({ sectionTitle, sectionTitleUp, data }) {
//   return (
//     <div className="container">
//       <div className="cs_gallery_grid_1">
//         <div className="cs_grid_item">
//           <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
//           <Spacing md="52" xl="52" lg="25" />
//         </div>
//         <div className="cs_grid_item"></div>
//         {data?.map((item, index) => (
//           <div className="cs_grid_item" key={index}>
//             <Portfolio key={index} {...item} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

import { useState } from 'react';
import Gallerypopup2 from './Gallerypopup2';
import Gallerypopup1 from './Gallerypopup1';
import Gallerypopup3 from './Gallerypopup3';
import Gallerypopup4 from './Gallerypopup4';
import Gallerypopup5 from './Gallerypopup5';

export default function GallerySection({ sectionTitle, sectionTitleUp, data }) {
  // Define state variables for each modal
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [isThirdModalOpen, setIsThirdModalOpen] = useState(false);
  const [isFourthModalOpen, setIsFourthModalOpen] = useState(false);
  const [isFifthModalOpen, setIsFifthModalOpen] = useState(false);

  // Modal open/close functions
  const openFirstModal = () => setIsFirstModalOpen(true);
  const closeFirstModal = () => setIsFirstModalOpen(false);

  const openSecondModal = () => setIsSecondModalOpen(true);
  const closeSecondModal = () => setIsSecondModalOpen(false);

  const openThirdModal = () => setIsThirdModalOpen(true);
  const closeThirdModal = () => setIsThirdModalOpen(false);

  const openFourthModal = () => setIsFourthModalOpen(true);
  const closeFourthModal = () => setIsFourthModalOpen(false);

  const openFifthModal = () => setIsFifthModalOpen(true);
  const closeFifthModal = () => setIsFifthModalOpen(false);

  return (
    <div className="container">
      <div className="cs_gallery_grid_1">
        <div className="cs_grid_item">
          <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
          <Spacing md="52" xl="52" lg="25" />
        </div>
        <div className="cs_grid_item"></div>
        {data?.map((item, index) => (
          <div className="cs_grid_item" key={index}>
            <Portfolio
              {...item}
              onClick={
                index === 0
                  ? openFirstModal
                  : index === 1
                    ? openSecondModal
                    : index === 2
                      ? openThirdModal
                      : index === 3
                        ? openFourthModal
                        : openFifthModal
              }
            />
          </div>
        ))}
      </div>

      {/* Modals */}
      {isFirstModalOpen && (
        <Gallerypopup1
          featureHeading={"Are Your Kidneys OK?"}
          GalleryImage1="/images/home/grant-funding.jpg"
          GalleryImage2="/images/home/grant-funding.jpg"
          GalleryImage3="/images/home/grant-funding.jpg"
          GalleryImage4="/images/home/grant-funding.jpg"
          GalleryImage5="/images/home/grant-funding.jpg"
          heading={" Gallery Images"}
          isOpen={isFirstModalOpen}
          onClose={closeFirstModal}
        />
      )}
      {isSecondModalOpen && (
        <Gallerypopup2
          featureHeading={"Are Your Kidneys OK?"}
          GalleryImage1="/images/home/grant-funding.jpg"
          GalleryImage2="/images/home/grant-funding.jpg"
          GalleryImage3="/images/home/grant-funding.jpg"
          GalleryImage4="/images/home/grant-funding.jpg"
          GalleryImage5="/images/home/grant-funding.jpg"
          heading={" Gallery Images"}
          isOpen={isSecondModalOpen}
          onClose={closeSecondModal}
        />
      )}
      {isThirdModalOpen && (
        <Gallerypopup3
          featureHeading={"Are Your Kidneys OK?"}
          GalleryImage1="/images/home/grant-funding.jpg"
          GalleryImage2="/images/home/grant-funding.jpg"
          GalleryImage3="/images/home/grant-funding.jpg"
          GalleryImage4="/images/home/grant-funding.jpg"
          GalleryImage5="/images/home/grant-funding.jpg"
          heading={" Gallery Images"}
          isOpen={isThirdModalOpen}
          onClose={closeThirdModal}
        />
      )}
      {isFourthModalOpen && (
        <Gallerypopup4
          featureHeading={"Are Your Kidneys OK?"}
          GalleryImage1="/images/home/grant-funding.jpg"
          GalleryImage2="/images/home/grant-funding.jpg"
          GalleryImage3="/images/home/grant-funding.jpg"
          GalleryImage4="/images/home/grant-funding.jpg"
          GalleryImage5="/images/home/grant-funding.jpg"
          heading={" Gallery Images"}
          isOpen={isFourthModalOpen}
          onClose={closeFourthModal}
        />
      )}
      {isFifthModalOpen && (
        <Gallerypopup5
          featureHeading={"Are Your Kidneys OK?"}
          GalleryImage1="/images/home/grant-funding.jpg"
          GalleryImage2="/images/home/grant-funding.jpg"
          GalleryImage3="/images/home/grant-funding.jpg"
          GalleryImage4="/images/home/grant-funding.jpg"
          GalleryImage5="/images/home/grant-funding.jpg"
          heading={" Gallery Images"}
          isOpen={isFifthModalOpen}
          onClose={closeFifthModal}
        />
      )}
    </div>
  );
}

