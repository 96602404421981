import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "./sass/index.scss";
import "./i18n/i18n";
import Loading from "./components/Loading";
import WhatsAppChatBot from "./components/WhatsappBot";
import { Provider } from "react-redux";
import { store } from "./services/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback={<Loading />}>
        <BrowserRouter>
          <App />
          <WhatsAppChatBot />
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  </React.StrictMode>
);
