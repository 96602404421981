import i18n from "../../i18n/i18n";

export const commonConstants = ()=>{

  const blogData = [
    {
      title: i18n.t("linkSections.list1.title", {
        ns: "common",
      }),
      subTitle:
        i18n.t("linkSections.list1.subTitle", {
          ns: "common",
        }),
      btnText:
        i18n.t("linkSections.list1.btnText", {
          ns: "common",
        }),
      href: "/kidney-education/kidney-diseases",
      socialShare: false,
    },
    {
      title: i18n.t("linkSections.list2.title", {
        ns: "common",
      }),
      subTitle:
        i18n.t("linkSections.list2.subTitle", {
          ns: "common",
        }),
      btnText:
        i18n.t("linkSections.list2.btnText", {
          ns: "common",
        }),
      href: "/our-services/counselling",
      socialShare: false,
    },
    {
      title: i18n.t("linkSections.list3.title", {
        ns: "common",
      }),
      subTitle:
        i18n.t("linkSections.list3.subTitle", {
          ns: "common",
        }),
      btnText:
        i18n.t("linkSections.list3.btnText", {
          ns: "common",
        }),
      href: "/our-services/counselling",
      socialShare: false,
    },
    {
      title: i18n.t("linkSections.list4.title", {
        ns: "common",
      }),
      subTitle:
        i18n.t("linkSections.list4.subTitle", {
          ns: "common",
        }),
      btnText:
        i18n.t("linkSections.list4.btnText", {
          ns: "common",
        }),
      href: "/contact",
      socialShare: false,
    },
  ];
  return  {
    blogData
  }
}