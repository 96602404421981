import React from "react";

const TextField = ({
  type = "text",
  field,
  parentDivClasses = "",
  customLabelClasses = "",
  customControlClasses = "",
  isImportant = false,
  label,
  txtName,
  placeholderText = "",
  helpText,
  errorText,
}) => {
  return (
    <div className={`form-group ${parentDivClasses}`}>
      {label && (
        <label
          htmlFor={`Txt${label}`}
          className={`cs_custom_label ${customLabelClasses}`}
        >
          {label}
          {isImportant && <sup>*</sup>}
        </label>
      )}
      <input
        {...field}
        type={type}
        className={`form-control cs_custom_txt_box ${customControlClasses}`}
        id={`Txt${label}`}
        name={txtName}
        aria-describedby={`${label}Help`}
        placeholder={placeholderText}
      />
      {helpText && (
        <small id={`${label}Help`} className="form-text text-muted">
          {helpText}
        </small>
      )}
      {errorText && <div className="invalid-feedback">{errorText}</div>}
    </div>
  );
};

export default TextField;
