import * as yup from "yup";

export const GenerateDietReportSchema = yup.object().shape({
  name: yup.string().required("Name is mandatory"),
  age: yup.number().required("Age is mandatory"),
  gender: yup.string().required("Gender is mandatory"),
  heightType: yup.string().required("Select measurement type"),
  height: yup.number().required("Height is mandatory"),
  weight: yup.number().required("Weight is mandatory"),
  activity: yup.string().required("Activity is mandatory"),
  illness: yup.string().required("Comorbid Illness is mandatory"),
  goal: yup.string().required("Weight management goal is mandatory"),
});

export default GenerateDietReportSchema;
