
import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function DownloadPost({
    title,
    subTitle = "",
    thumbUrl,
    date,
    href,
    socialShare,
    variant,
    gradient,
    filename,
    pdfurl,
    dowbtnText,
    btnArrowUrl,
    btnText, // Add btnText here
  }) {
    return (
      <div
        style={{
          backgroundImage: gradient
            ? `linear-gradient(${gradient?.a}, ${gradient?.b})`
            : "",
        }}
        className={`cs_post cs_style_1 ${variant ?? ""}`}
      >
        {/* Thumbnail Section */}
        {thumbUrl && (
          <Link to={href} className="cs_post_thumb cs_view_mouse">
            <img loading="lazy" src={thumbUrl} alt={title} />
          </Link>
        )}
  
        <div className="cs_post_info">
          <div>
            {/* Social Share Icons */}
            {socialShare && (
              <div className="cs_post_meta">
                <div className="cs_post_social">
                  <Link to="/" className="cs_center rounded-circle">
                    <Icon icon="fa-brands:linkedin-in" />
                  </Link>
                  <Link to="/" className="cs_center rounded-circle">
                    <Icon icon="fa-brands:facebook-f" />
                  </Link>
                  <Link to="/" className="cs_center rounded-circle">
                    <Icon icon="fa-brands:twitter" />
                  </Link>
                </div>
              </div>
            )}
  
            {/* Title and Subtitle */}
            <h2 className="cs_post_titl cs_semibold cs_fs_32">
              {href ? <Link to={href}>{title}</Link> : title}
            </h2>
            {subTitle && <p className="m-0" style={{ color: "#4a4a4a" }}>{subTitle}</p>}
  
          </div>
  
          {/* Button or Download Link */}
          {btnText && (
            <Link to={href || "#"} className="cs_download_btn">
              {btnText} <i class="fa-brands fa-readme cs_read_icon"></i>
            </Link>
          )}
  
          {pdfurl && dowbtnText && (
            <a href={pdfurl} download={filename} className="cs_download_btn">
              {dowbtnText}
              {btnArrowUrl && (
                <img
                  src={btnArrowUrl}
                  alt="Download arrow"
                  className="cs_download_icon"
                />
              )}
            </a>
          )}
        </div>
      </div>
    );
  }
  