import React from "react";

import TextField from "../../components/TextField";
import SelectField from "../../components/SelectField";
import { Controller } from "react-hook-form";

const DietForm = ({ onSubmit, methods }) => {
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <form className="row cs_diet_form">
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <TextField
            field={field}
            isImportant={true}
            label="Name"
            txtName="name"
            placeholderText="Enter your full name"
            errorText={errors?.name?.message?.toString()}
          />
        )}
      />

      <Controller
        control={control}
        name="age"
        render={({ field }) => (
          <TextField
            field={field}
            type="number"
            isImportant={true}
            label="Age in years"
            txtName="age"
            placeholderText="Enter your age in years"
            errorText={errors?.age?.message?.toString()}
          />
        )}
      />

      <Controller
        control={control}
        name="gender"
        render={({ field }) => (
          <SelectField
            field={field}
            isImportant={true}
            label="Gender"
            ddlName="gender"
            options={[
              { value: "", label: "--- Select ---" },
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
              { value: "others", label: "Others" },
            ]}
            errorText={errors?.gender?.message?.toString()}
          />
        )}
      />

      <div className="cs_diet_form_height_sec">
        <div className="cs_user_height">
          <Controller
            control={control}
            name="height"
            render={({ field }) => (
              <TextField
                field={field}
                isImportant={true}
                label="Height"
                txtName="height"
                placeholderText="Enter your height based on type"
                errorText={errors?.height?.message?.toString()}
              />
            )}
          />
        </div>

        <div className="cs_user_height_type">
          <Controller
            control={control}
            name="heightType"
            render={({ field }) => (
              <SelectField
                field={field}
                isImportant={true}
                label="Type"
                ddlName="heightType"
                options={[
                  { value: "", label: "--- Select ---" },
                  { value: "cms", label: "Centimeters" },
                  { value: "feet", label: "Feet" },
                ]}
                errorText={errors?.heightType?.message?.toString()}
              />
            )}
          />
        </div>
      </div>

      <Controller
        control={control}
        name="weight"
        render={({ field }) => (
          <TextField
            field={field}
            type="number"
            isImportant={true}
            label="Weight"
            txtName="weight"
            placeholderText="Enter your weight"
            errorText={errors?.weight?.message?.toString()}
          />
        )}
      />

      <Controller
        control={control}
        name="activity"
        render={({ field }) => (
          <SelectField
            field={field}
            isImportant={true}
            label="Activity"
            ddlName="activity"
            options={[
              { value: "", label: "--- Select ---" },
              { value: "sedentary", label: "Sedentary" },
              { value: "mildly-active", label: "Mildly active" },
              { value: "moderately-active", label: "Moderately active" },
              { value: "very-active", label: "Very active" },
            ]}
            errorText={errors?.activity?.message?.toString()}
          />
        )}
      />

      <Controller
        control={control}
        name="illness"
        render={({ field }) => (
          <SelectField
            field={field}
            isImportant={true}
            label="Comorbid Illness"
            ddlName="illness"
            options={[
              { value: "", label: "--- Select ---" },
              { value: "diabetes", label: "Diabetes" },
              { value: "hypertension", label: "Hypertension" },
              { value: "kidney-disease", label: "Kidney disease" },
              { value: "heart-disease", label: "Heart disease" },
              { value: "liver-gut-disease", label: "Liver and Gut disease" },
            ]}
            errorText={errors?.illness?.message?.toString()}
          />
        )}
      />

      <Controller
        control={control}
        name="goal"
        render={({ field }) => (
          <SelectField
            field={field}
            isImportant={true}
            label="Wish to adopt our weight management goal"
            ddlName="goal"
            options={[
              { value: "", label: "--- Select ---" },
              { value: "yes", label: "Yes" },
              { value: "no", label: "No" },
            ]}
            errorText={errors?.goal?.message?.toString()}
          />
        )}
      />
    </form>
  );
};

export default DietForm;
