import React from "react";
import Button from "../../Button";
import Spacing from "../../Spacing";

export default function DownloadBannerSectionStyle({
  bgUrl,
  imgUrl,
  subHeadTitle = "",

  btnText,
  btnUrl,
  customeThemeClass = "",
}) {
  return (
    <section
      className="cs_banner cs_style_3 cs_bg_filed cs_kidney_education_banner cs_kidney_needs_banner"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      {imgUrl && (
        <div className="cs_banner_img">
          <img loading="lazy" src={imgUrl} alt="Banner" className="cs_main_banner_img" />
        </div>
      )}
      <div className="container">
      <Spacing lg="600" md="170" />
        <div className="cs_banner_text">
         
          {subHeadTitle && (
            <h3 className={`cs_hero_title cs_white_color cs_fs_48 ${customeThemeClass}`}>
              {subHeadTitle}
            </h3>
          )}
          {btnText && (
            <>
              <Spacing md="25" lg="25" xl="25" />
              <Button btnUrl={btnUrl} btnText={btnText} />
            </>
          )}
        </div>
      </div>
    </section>
  );
}
