import React from 'react';
import BannerSectionStyle11 from '../Section/BannerSection/BannerSectionStyle11';
import Section from '../Section';
import TeamSection from '../Section/TeamSection';
import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
// import { commonConstants } from "../../pages/common/commonConstants";
import BlogSection from "../Section/BlogSection";
// import { useTranslation } from "react-i18next";
import AboutSection from "../Section/AboutSection";
import KidneyBreadcrumStyle from '../Breadcrumb/KidneyBreadcrumStyle';
// import Spacing from '../Spacing';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import FeaturesSectionStyle6 from '../Section/FeaturesSection/FeaturesSectionStyle6';
import AboutBlogSection from '../Section/BlogSection/AboutBlogSection';
import Spacing from '../Spacing';
import SingleGridAccordion from '../SingleGridAccordion/SingleGridAccordion';
import List from '../List';
// import FeaturesSectionStyle5 from '../Section/FeaturesSection/FeaturesSectionStyle5';
// import { Link } from 'react-router-dom';


// const departmentData = [
//   {
//     title: 'Diagnostic testing',
//     subTitle:
//       'Blood tests, imaging studies, and other tests to diagnose health conditions',
//     iconUrl: '/images/icons/calendar_white.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Rehabilitation services',
//     subTitle:
//       'Physical therapy, occupational therapy, and other services to help patients recover from injuries',
//     iconUrl: '/images/icons/calendar_white.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Preventive care',
//     subTitle:
//       'Annual checkups, immunizations, and health screenings care preventive',
//     iconUrl: '/images/icons/calendar_white.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Treatment for acute and chronic conditions',
//     subTitle:
//       'Medication management, disease management, and other treatments to improve health outcomes',
//     iconUrl: '/images/icons/calendar_white.svg',
//     href: '/departments/department-details',
//   },
//   {
//     title: 'Mental health services',
//     subTitle:
//       'Counselling, therapy, and other services to help patients manage mental health conditions',
//     iconUrl: '/images/icons/calendar_white.svg',
//     href: '/departments/department-details',
//   },
// ];

// const featureListData = [
//   {
//     title: 'Experienced Medical Professionals',
//     subTitle:
//       'Our team includes experienced doctors, nurses, <br />and other healthcare professionals who are <br />dedicated to providing the best possible care to <br />our patients.',
//     iconUrl: 'images/icons/professional.svg',
//   },
//   {
//     title: 'Comprehensive <br />Services',
//     subTitle:
//       'We offer a wide range of healthcare services, <br />from preventive care to specialized treatment <br />for complex conditions.',
//     iconUrl: 'images/icons/comprehensive.svg',
//   },
//   {
//     title: 'Patient-centered <br />Approach',
//     subTitle:
//       'We believe in treating each patient as an <br />individual, and we take the time to understand <br />your unique health needs and concerns.',
//     iconUrl: 'images/icons/patient.svg',
//   },
//   {
//     title: 'State-of-the-art <br />Facilities',
//     subTitle:
//       'Our healthcare center is equipped with the <br />latest technology and equipment to provide our <br />patients with the most advanced care possible.',
//     iconUrl: 'images/icons/facilities.svg',
//   },
// ];

// const funFactData = [
//   { number: '20+', title: 'Years of experience' },
//   { number: '95%', title: 'Patient satisfaction rating' },
//   { number: '5000+', title: 'Patients served annually' },
//   { number: '10+', title: 'Healthcare providers on staff' },
//   { number: '22+', title: 'Convenient locations in the area' },
// ];

const teamData = [
  {
    imgUrl: 'images/about/Doctors320x230-01.jpg',
    name: 'Chief Doctors Team',
    designation: 'Our Specialist Doctors',
    description:
      'With expertise in managing complex kideny conditions and performing advanced procedures',
  },
  {
    imgUrl: 'images/about/Doctors320x230-02.jpg',
    name: 'Chief Dietitian Team',
    designation: 'Dietitian Experts of India',
    description:
      'With expertise in managing kidney health via diet recommendations',
  },
  {
    imgUrl: 'images/about/Doctors320x230-03.jpg',
    name: ' Technical Team',
    designation: 'Our Backend Soldiers',
    description:
      'Experts handling our operations behind the scenes',

  },
];
// const mightyMarvelsData =[
//   {
//     title:"Mission",
//     subTitle:"To provide comprehensive patient care by working as a team and offering highest standards of medical service in an ethical manner for delivering extraordinary customer satisfaction.",
//     iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 1-01.svg",
//   },
//   {
//     title:"Vision",
//     subTitle:"To create an Integrated World Class Healthcare System, Fostering, Protecting, Sustaining and Restoring Health through Best in Class Medical Practices and Cutting Edge Technology developed through in depth Research carried out by the World's Best Scientific Minds.",
//     iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 1-01.svg",
//   },
//   {
//     title:"Values",
//     subTitle:"Patients come first in everything we do. respect and dignity. We value every person – whether patient, their families or carers, or staff – as an individual, respect their aspirations and commitments in life, and seek to understand their priorities, needs, abilities and limits.",
//     iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 1-01.svg",
//   }
// ]

const aboutUsData = [
  {
    title: "Kidney needs",
    subTitle:
      "Compassionate Support for Your Kidney Health Journey",
    thumbUrl: "images/home/791x751-01ab.jpg",
    date: "May 1, 2023",
    // btnText: "Learn More",
    // href: "/kidney-education/kidney-diseases",
    socialShare: false,
  },
  {
    title: " Mrutanya Medicines",
    subTitle:
      "Access Quality, Affordable Medicines for Your Kidney Health",
    thumbUrl: "images/home/791x751-02ab.jpg",
    date: "May 4, 2023",
    // btnText: "Learn More",
    // href: "/our-services/counselling",
    socialShare: false,
  },
  {
    title: "medical products",
    subTitle:
      "KidneyNeeds: One-Stop Shop for Catheter Solutions",
    thumbUrl: "images/home/791x751-03ab.jpg",
    date: "May 1, 2023",
    // btnText: "Learn More",
    // href: "/our-services/counselling",
    socialShare: false,
  },
  {
    title: "Dialysis Accessories",
    subTitle:
      "KidneyNeeds: Your One-Stop Shop for Dialysis Accessories",
    thumbUrl: "images/home/791x751-04ab.jpg",
    date: "May 1, 2023",
    // btnText: "Learn More",
    // href: "/contact",
    socialShare: false,
  },
];

const otherInitiativeDate = [
  {
    title: "Kideny Biopsy",
    subTitle:
      "Precision Biopsy Tools for Kidney and Bone Marrow",
    thumbUrl: "images/home/791x751-05ab.jpg",
    date: "May 1, 2023",
    // btnText: "Learn More",
    // href: "/kidney-education/kidney-diseases",
    socialShare: false,
  },
  {
    title: "Health Records",
    subTitle:
      " Taking Control of Your Chronic Kidney Disease, Manage Your Health Records with Us ",
    thumbUrl: "images/home/791x751-08ab.jpg",
    date: "May 4, 2023",
    // btnText: "Learn More",
    // href: "/our-services/counselling",
    socialShare: false,
  },
  {
    title: "E-Learning",
    subTitle:
      "Empower Your Kidney Health Journey with Kidney Needs' CKD e-Learning Course.",
    thumbUrl: "images/home/791x751-06ab.jpg",
    date: "May 1, 2023",
    // btnText: "Learn More",
    // href: "/our-services/counselling",
    socialShare: false,
  },
  {
    title: "Kidney Diet",
    subTitle:
      "A Healthy Diet is Not Just About Restrictions, It's About Delicious Possibilities That Fuel Your Well-Being.",
    thumbUrl: "images/home/791x751-09ab.jpg",
    date: "May 1, 2023",
    // btnText: "Learn More",
    // href: "/contact",
    socialShare: false,
  },
];

const awardData = [
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Malcolm Baldrige National Quality Award',
  },
  { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Healthgrades National’s Best Hospital',
  },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Joint Commission Gold Seal of Approval',
  },
];

const missiondata = [
  {
    title: "Mission",
    iconUrl: "/images/home/remember-icon-our-services.svg",
    content: (
      <List
        heading=""
        description= "A mission statement is a short statement that describes the purpose and the reason for the hospital's existence."
        data={[]}
      />
    ),
  },
];

const visiondata = [
  {
    title: "Vision",
    iconUrl: "/images/home/remember-icon-our-services.svg",
    content: (
      <List
        heading=""
        description= "A vision statement consists of the critical characteristics that leadership wants for the hospital's future."
        data={[]}
      />
    ),
  },
];

const valuesdata = [
  {
    title: "Values",
    iconUrl: "/images/home/remember-icon-our-services.svg",
    content: (
      <List
        heading=""
        description= "Patients come first in everything we do. respect and dignity. We value every person – whether patient, their families or carers, or staff – as an individual, respect their aspirations and commitments in life, and seek to understand their priorities, needs, abilities and limits."
        data={[]}
      />
    ),
  },
];

export default function About() {
  pageTitle('About');
  // const { t } = useTranslation(["common"]);
  // const { blogData } = commonConstants();

  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      
      <BannerSectionStyle11
        // bgUrl="/images/about/banner_bg.svg"
        // imgUrl="/images/about/banner_img.png"
        // title="Welcome to <br />KidneyNeeds Medical & Healthcare Center"
        // subTitle="Your Partner in Health and Wellness"
      />
       {/* <Spacing md="55" lg="30" /> */}
       {/* <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_healthy_kidneys"
      
      >
        <FeaturesSectionStyle5
          sectionTitle="Kidney Needs Mission, Vision, Values."
          // sectionSubTitle="jcgc"
          data={mightyMarvelsData}
        />
      </Section> */}
       <Section
        topMd={50}
        topLg={50}
        topXl={50}
        className="cs_home_info_sec"
      >
        <div className="container">
          <h2>Kidney Needs Mission, Vision, Values</h2>
          <Spacing md="30" lg="30" /> 
          <div className="row g-4 g-xl-3 g-xxl-5">
            <div
              className="col-xl-4"
              style={{ cursor: "pointer" }}
              // onClick={openGrantModal}
            >
             <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        // sectionRef={remember1Ref}
      >
        <div className="cs_accordion_ref">
          <SingleGridAccordion
            accordionData={missiondata}
            customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
          />
        </div>
      </Section>
            </div>
           
            <div
              className="col-xl-4"
              style={{ cursor: "pointer" }}
              // onClick={openArtModal}
            >
              
              <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        // sectionRef={remember1Ref}
      >
        <div className="cs_accordion_ref">
          <SingleGridAccordion
            accordionData={visiondata}
            customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
          />
        </div>
      </Section>
             
            </div>
           
            <div
              className="col-xl-4"
              style={{ cursor: "pointer" }}
             
            >
              <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        // sectionRef={remember1Ref}
      >
        <div className="cs_accordion_ref">
          <SingleGridAccordion
            accordionData={valuesdata}
            customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
          />
        </div>
      </Section>
            </div>
          </div>
          
        </div>

      </Section>
      

      <Section topMd={20} topLg={10} topXl={10}>
        <div className='container' bottomMd={130} bottomLg={120} bottomXl={40}>
         <h2>Our Outher Products</h2>
        </div>
        <Spacing md="22" lg="30" />
        <AboutBlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={aboutUsData} />
      </Section>
      {/* <Section topMd={175} topLg={125} topXl={85}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section> */}
      {/* <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section> */}
      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Doctor Experts"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <AboutSection
          imgUrl="/images/home/about.jpg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="KIDNEYNEEDS"
          featureList={[
            {
              featureListTitle:
                "KidneyNeeds is a team of experienced medical professionals",
              featureListSubTitle:
                "Dedicated to providing top-quality healthcare services. We believe in a holistic approach to healthcare that focuses on treating the whole person, not just the illness or symptoms.",
            },
          ]}
        />
      </Section>
      {/* <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Facilities and <br />Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section> */}
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section>

      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={otherInitiativeDate} />
      </Section>

      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/Bottom-Banner-ab-new.png"
          title="Don’t Let You & Your Loved Ones Healthy Life At Risk!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
          content="Give Us Missed Call: +91 91116 06162"
          center
        />
      </Section>

      <Section
       topMd={190}
       topLg={145}
       topXl={105}
      //  bottomMd={200}
      //  bottomLg={150}
      //  bottomXl={110}
        className="cs_white_bg cs_transplant"
        // sectionRef={organDonorRef}
      >
        <FeaturesSectionStyle6
          sectionTitle="Village and City Kidney Needs - Initiative"
          image1="images/about/City-Banner_new.png"
          image2="images/about/Village-Banner-new.png"
          // data={whyBecomeAnOrganDonorData}
        />
      </Section>
    </>
  );
}
