import React from "react";
import { Link } from "react-router-dom";
import "../../sass/shortcode/smallnav.scss"

export default function Smallnav() {

  return (
    <>
      <div className="navbar-one flex">
        <div className="left flex">
          <div className="email">
            <i className="fa fa-envelope"></i>
            <a href="mailto:support@kidneyneeds.com">support@kidneyneeds.com</a>
            <span></span>
          </div>
          <div className="call">
            <i class="fa-solid fa-phone"></i>
            <a href="tel:+919111606162">+91 91116 06162</a>
          </div>
        </div>

        <div className="right flex">
          <div className="logins">
            {/* <span>Login</span> */}
          </div>
          <div className="oneclick">
            <Link to="/contact">One Click Solution</Link>
          </div>

        </div>

      </div>
    </>
  );
}
