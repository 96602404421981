import { combineReducers } from "@reduxjs/toolkit";

import dietSlice from "./slices/dietSlice";

const rootReducer = combineReducers({
  diet: dietSlice,
});

export const RTKApiReducers = [];

export const RTKApiMiddlewares = [];

export const proxyReducer = (state, action) => {
  if (action.type === "resetAppState") return rootReducer(undefined, action);

  return rootReducer(state, action);
};

export default proxyReducer;
