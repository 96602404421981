import React from "react";

export default function BannerSectionStyle11({
  bgUrl,
  imgUrl,
  title,
  subHeadTitle = "",
  subTitle,
  btnText,
  btnUrl,
  customeThemeClass = "",
}) {
  return (
    <section
      className="cs_banner cs_style_11 cs_bg_filed" 
    >

      <div className="cs_banner_video">
        <video muted playsInline autoPlay loop className="cs_main_banner_video">
          {/* <source src="/videos/video_sample.mp4" type="video/mp4" /> */}
          <source src="/videos/samplevideo.mp4" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      {/* <div className="container">
        <div className="cs_banner_text">
          <h2 className="cs_banner_title cs_fs_72">{parse(title)}</h2>
          {subHeadTitle && (
            <h3 className={`cs_hero_title cs_fs_48 ${customeThemeClass}`}>
              {subHeadTitle}
            </h3>
          )}
          <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
            {parse(subTitle)}
          </p>
          {btnText && (
            <>
              <Spacing md="25" lg="25" xl="25" />
              <Button btnUrl={btnUrl} btnText={btnText} />
            </>
          )}
        </div>
      </div> */}
    </section>
  );
}
