import React from 'react'
import Spacing from '../Spacing'
import Section from '../Section'
import BlogList from '../../pages/Blogs/BlogComponents/BlogList'
import { firstBlogContentDatas } from '../../pages/HomeBlogs/firstBlogContents'
import { Trans, useTranslation } from 'react-i18next'
import CustomTable from '../Table'
import ContactForm from '../ContactForm'

function Research() {
  const { t } = useTranslation(["homeBlog"]);
  const {
    whyJEEVANIsNeededListData,
    howWeMakeItPossibleListData,
    illustrativePrescriptionCostComparisonListData,
    estimatedMonthlyCostWithJEEVANListData,
  } = firstBlogContentDatas();
  return (
    <>
      <div className='container'>
        <Spacing lg="100" md="160" />
        <Section bottomMd={130} bottomLg={120} bottomXl={40}>
          <h4>KidneyNeeds' JEEVAN Kidney Initiative</h4>
          <h1>Empowering Lives through Affordable, Quality Kidney Care</h1>

          <img
            src="/images/home/dialysis-two-types-images.jpg"
            alt="Blog Details"
            className="w-100 cs_radius_20"
          />


        </Section>
        <h2>{t(`blogs.101.innerHTML.revolutionizingKidneyCare.title`)}</h2>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.rajeshStory.title`)}</h4>
        <p>{t(`blogs.101.innerHTML.rajeshStory.description.para1`)}</p>
        <p>{t(`blogs.101.innerHTML.rajeshStory.description.para2`)}</p>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.jeevan.title`)}</h4>
        <p>{`"${t(`blogs.101.innerHTML.jeevan.description`)}"`}</p>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.title`)}</h4>
        <p>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.description.para1`)}</p>
        <p>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.description.para2`)}</p>
        <div className="cs_blog_un_ord_list">
          <BlogList data={whyJEEVANIsNeededListData} />
        </div>
        <p>{t(`blogs.101.innerHTML.whyJEEVANIsNeeded.notes`)}</p>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.howWeMakeItPossible.title`)}</h4>
        <div className="cs_blog_un_ord_list">
          <BlogList data={howWeMakeItPossibleListData} />
        </div>
        <p>{t(`blogs.101.innerHTML.howWeMakeItPossible.notes`)}</p>

        <Spacing md="20" lg="20" />
        <h4>
          {t(`blogs.101.innerHTML.illustrativePrescriptionCostComparison.title`)}
        </h4>
        <p>
          {t(
            `blogs.101.innerHTML.illustrativePrescriptionCostComparison.description`
          )}
        </p>
        <div className="cs_blog_un_ord_list">
          <BlogList data={illustrativePrescriptionCostComparisonListData} />
        </div>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.estimatedMonthlyCostWithJEEVAN.title`)}</h4>
        <div className="cs_blog_un_ord_list">
          <BlogList data={estimatedMonthlyCostWithJEEVANListData} />
        </div>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.ensuringQualityAccess.title`)}</h4>
        <p>{t(`blogs.101.innerHTML.ensuringQualityAccess.description.para1`)}</p>
        <p>{t(`blogs.101.innerHTML.ensuringQualityAccess.description.para2`)}</p>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.yourPersonalizedPath.title`)}</h4>
        <p>
          <Trans
            i18nKey={t("blogs.101.innerHTML.yourPersonalizedPath.description")}
            components={{
              boldText: <b />,
            }}
          />
        </p>

        <Spacing md="20" lg="20" />
        <h4>{t(`blogs.101.innerHTML.conclusion.title`)}</h4>
        <p>{t(`blogs.101.innerHTML.conclusion.description`)}</p>

        <Spacing md="20" lg="20" />
        <h3>{t(`blogs.101.innerHTML.notes`)}</h3>
        <CustomTable />
        <Spacing md="20" lg="20" />
        <ContactForm customStyle={{ formContainer: "pop-up-contact" }} />
      </div>
    </>
  )
}

export default Research