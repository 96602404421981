import i18n from "../../../i18n/i18n";

export const healthyKidneysContentDatas = () => {
  const mightyMarvelsData = [
    {
      title: i18n.t("theMightyMarvelsLists.list1.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("theMightyMarvelsLists.list1.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 1-01.svg",
    },
    {
      title: i18n.t("theMightyMarvelsLists.list2.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("theMightyMarvelsLists.list2.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 2-01.svg",
    },
    {
      title: i18n.t("theMightyMarvelsLists.list3.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("theMightyMarvelsLists.list3.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 3-01.svg",
    },
    {
      title: i18n.t("theMightyMarvelsLists.list4.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("theMightyMarvelsLists.list4.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 4-01.svg",
    },
    {
      title: i18n.t("theMightyMarvelsLists.list5.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("theMightyMarvelsLists.list5.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 5-01.svg",
    },
  ];

  const whyHealthyKidneysData = [
    {
      title: i18n.t("whyHealthyKidneysMatterLists.list1.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("whyHealthyKidneysMatterLists.list1.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 6-01.svg",
    },
    {
      title: i18n.t("whyHealthyKidneysMatterLists.list2.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("whyHealthyKidneysMatterLists.list2.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 7-01.svg",
    },
    {
      title: i18n.t("whyHealthyKidneysMatterLists.list3.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("whyHealthyKidneysMatterLists.list3.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 8-01.svg",
    },
    {
      title: i18n.t("whyHealthyKidneysMatterLists.list4.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("whyHealthyKidneysMatterLists.list4.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 9-01.svg",
    },
    {
      title: i18n.t("whyHealthyKidneysMatterLists.list5.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("whyHealthyKidneysMatterLists.list5.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 10-01.svg",
    },
  ];

  const simpleStepsData = [
    {
      title: i18n.t("simpleStepsForStellarKidneyHealthLists.list1.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t(
        "simpleStepsForStellarKidneyHealthLists.list1.description",
        {
          ns: "healthyKidneys",
        }
      ),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 11-01.svg",
    },
    {
      title: i18n.t("simpleStepsForStellarKidneyHealthLists.list2.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t(
        "simpleStepsForStellarKidneyHealthLists.list2.description",
        {
          ns: "healthyKidneys",
        }
      ),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 12-01.svg",
    },
    {
      title: i18n.t("simpleStepsForStellarKidneyHealthLists.list3.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t(
        "simpleStepsForStellarKidneyHealthLists.list3.description",
        {
          ns: "healthyKidneys",
        }
      ),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 13-01.svg",
    },
    {
      title: i18n.t("simpleStepsForStellarKidneyHealthLists.list4.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t(
        "simpleStepsForStellarKidneyHealthLists.list4.description",
        {
          ns: "healthyKidneys",
        }
      ),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 14-01.svg",
    },
    {
      title: i18n.t("simpleStepsForStellarKidneyHealthLists.list5.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t(
        "simpleStepsForStellarKidneyHealthLists.list5.description",
        {
          ns: "healthyKidneys",
        }
      ),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 15-01.svg",
    },
    {
      title: i18n.t("simpleStepsForStellarKidneyHealthLists.list6.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t(
        "simpleStepsForStellarKidneyHealthLists.list6.description",
        {
          ns: "healthyKidneys",
        }
      ),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 16-01.svg",
    },
    {
      title: i18n.t("simpleStepsForStellarKidneyHealthLists.list7.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t(
        "simpleStepsForStellarKidneyHealthLists.list7.description",
        {
          ns: "healthyKidneys",
        }
      ),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 13-01.svg",
    },
  ];

  const infographicIdeasData = [
    {
      title: i18n.t("infographicIdeasLists.list1.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("infographicIdeasLists.list1.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 17-01.svg",
    },
    {
      title: i18n.t("infographicIdeasLists.list2.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("infographicIdeasLists.list2.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 18-01.svg",
    },
    {
      title: i18n.t("infographicIdeasLists.list3.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("infographicIdeasLists.list3.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 19-01.svg",
    },
    {
      title: i18n.t("infographicIdeasLists.list4.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("infographicIdeasLists.list4.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 20-01.svg",
    },
    {
      title: i18n.t("infographicIdeasLists.list5.title", {
        ns: "healthyKidneys",
      }),
      subTitle: i18n.t("infographicIdeasLists.list5.description", {
        ns: "healthyKidneys",
      }),
      iconUrl: "/images/kidney_educations/healthy-kidneys/Icon 18-01.svg",
    },
  ];

  const blogInfoGrapics = [
    {
      title: "",
      description: "",
      btnRedirectText: "",
      imgURL: "/images/kidney_educations/healthy-kidneys/info01.png",
    },
    {
      title: "",
      description: "",
      btnRedirectText: "",
      imgURL: "/images/kidney_educations/healthy-kidneys/info02.png",
    },
    {
      title: "",
      description: "",
      btnRedirectText: "",
      imgURL: "/images/kidney_educations/healthy-kidneys/info03.png",
    },
    {
      title: "",
      description: "",
      btnRedirectText: "",
      imgURL: "/images/kidney_educations/healthy-kidneys/info04.png",
    },
  ]
  return {
    mightyMarvelsData,
    whyHealthyKidneysData,
    simpleStepsData,
    infographicIdeasData,
    blogInfoGrapics
  };
};
