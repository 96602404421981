import { persistReducer } from "redux-persist";
import rootReducer, { RTKApiReducers } from "./reducers";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";

import logger from "redux-logger";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "root",
  version: "1.0",
  blacklist: RTKApiReducers,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger, thunk),
});
