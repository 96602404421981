import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Gallerypopup4 = ({ isOpen, onClose, heading }) => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div
      className={`modal  modal-dialog-scrollable modal-lg ${isOpen ? "show" : ""
        }`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog fullscreen_height modal-fullscreen">
        <div className="modal-content" style={{ marginBottom: 100 }}>
          <div className="modal-header">
            <h5 className="modal-title">{heading || 'Contact Us'}</h5>
            <button type="button" onClick={onClose} className="btn btn-link" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

          <Carousel responsive={responsive}>
        <img src="images/gallery/Gallery_Images1500x644-11.jpg" alt="hoverText" />
        <img src="images/gallery/Gallery_Images1500x644-12.jpg" alt="hoverText" />
        <img src="images/gallery/Gallery_Images1500x644-13.jpg" alt="hoverText" />
        <img src="images/gallery/Gallery_Images1500x644-01.jpg" alt="hoverText" />
        <img src="images/gallery/Gallery_Images1500x644-02.jpg" alt="hoverText" />
          </Carousel>


            {/* <ContactForm customStyle={{ formContainer: "pop-up-contact" }} /> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {/* {isOpen && <div className="modal-backdrop fade show"></div>} */}
    </div>
  );
};

export default Gallerypopup4;
