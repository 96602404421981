import React from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
import Post from "../../Post";

export default function BlogSection({
  data,
  sectionTitle,
  sectionTitleUp,
  sectionTitleDown,
  sectionSubTitle,
  customeThemeClass
}) {
  const colorGradient = [
    {
      a: "#f79226",
      b: "#f05445",
    },
    {
      a: "#40bfbb",
      b: "#079247",
    },
    {
      a: "#2baae2",
      b: "#3957a6",
    },
    {
      a: "#f05b87",
      b: "#ee2828",
    },
  ];
  return (
    <div className={`container ${customeThemeClass? customeThemeClass: ''}`}>
      {sectionTitle ||
        sectionTitleUp ||
        sectionTitleDown ||
        (sectionSubTitle && (
          <>
            {" "}
            <SectionHeading
              title={sectionTitle}
              titleUp={sectionTitleUp}
              titleDown={sectionTitleDown}
              subTitle={sectionSubTitle}
              center
            /> 
            <Spacing md="72" lg="50" />
          </>
        ))}

      <div className="row gy-4">
        {data?.map((item, index) => (
          <div className="col-lg-3" key={index}>
            <Post {...item} gradient={colorGradient[index]} />
          </div>
        ))}
      </div>
    </div>
  );
}
// import React from "react";
// import SectionHeading from "../../SectionHeading";
// import Spacing from "../../Spacing";
// import Post from "../../Post";
// export default function BlogSection({
//   data,
//   sectionTitle,
//   sectionTitleUp,
//   sectionTitleDown,
//   sectionSubTitle,
//   customeThemeClass,
// }) {
//   const colorGradient = [
//     { a: "#f79226", b: "#f05445" },
//     { a: "#40bfbb", b: "#079247" },
//     { a: "#2baae2", b: "#3957a6" },
//     { a: "#f05b87", b: "#ee2828" },
//   ];

//   return (
//     <div className={`container ${customeThemeClass || ""}`}>
//       {(sectionTitle || sectionTitleUp || sectionTitleDown || sectionSubTitle) && (
//         <>
//           <SectionHeading
//             title={sectionTitle}
//             titleUp={sectionTitleUp}
//             titleDown={sectionTitleDown}
//             subTitle={sectionSubTitle}
//             center
//           />
//           <Spacing md="72" lg="50" />
//         </>
//       )}

//       <div className="row gy-4">
//         {data?.map((item, index) => (
//           <div className="col-lg-3" key={index}>
//             <Post
//               {...item}
//               dowbtnText={item.dowbtnText}
//               btnArrowUrl={item.btnArrowUrl}
//               gradient={colorGradient[index % colorGradient.length]}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
