import React from "react";
import { useSelector } from "react-redux";

const DietFormDetails = ({ steps }) => {
  const dietDetails = useSelector((state) => state.diet.dietEntry);

  return (
    <>
      {dietDetails && Object.keys(dietDetails).length > 0 && (
        <div className="cs_diet_detailed_page">
          <div className="cs_diet_patient_info_sec">
            {steps === 1 && (
              <>
                <div className="cs_diet_patient_details">
                  <h4>Name</h4>
                  <p>{dietDetails.name}</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Gender</h4>
                  <p>{dietDetails.gender}</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Weight</h4>
                  <p>{dietDetails.weight} kg</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Activity</h4>
                  <p>{dietDetails.activity}</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Age</h4>
                  <p>{dietDetails.age} years</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Height</h4>
                  <p>{`${dietDetails.height} ${dietDetails.heightType}`}</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Comorbid Illness</h4>
                  <p>{dietDetails.illness}</p>
                </div>
              </>
            )}
            {steps === 2 && (
              <>
                <div className="cs_diet_patient_details">
                  <h4>BMI</h4>
                  <p>{dietDetails.bmi}</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Ideal Body Weight</h4>
                  <p>{dietDetails.ibw}</p>
                </div>
                <div className="cs_diet_patient_details">
                  <h4>Adjusted Body Weight</h4>
                  <p>{dietDetails.abw}</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DietFormDetails;
