// CardFilterSection.js
import React from 'react';
import Card from './Faqcard';

function CardFilterSection({ cards }) {
  return (
    <div className="card-container">
      {cards.map((card) => (
        <Card key={card.id} data={card} />
      ))}
    </div>
  );
}

export default CardFilterSection;
