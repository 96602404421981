import React from "react";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import SingleGridAccordion from "../SingleGridAccordion/SingleGridAccordion";

const MultiGridAccordian = ({
  bgUrl,
  headingTitle,
  headingSubTitle,
  leftSideDatas = [],
  rightSideDatas = [],
  customClasses = "",
}) => {
  return (
    <div className="container">
      <div className="cs_departments cs_style_1">
        <div
          className="cs_departments_bg cs_radius_25"
          style={{
            backgroundImage: `url(${bgUrl})`,
          }}
        />
        <SectionHeading
          title={headingTitle}
          subTitle={headingSubTitle}
          center
        />
        <Spacing md="72" lg="50" />
        <div className="cs_department_list">
          <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
            <div className="row">
              <div className="col-lg-6 col-xl-6 cs_multi_accord_left">
                {leftSideDatas &&
                  leftSideDatas.length > 0 &&
                  leftSideDatas.map((accord, index) => (
                    <>
                      <SingleGridAccordion
                        accordionData={accord}
                        customClasses={customClasses}
                      />
                      {leftSideDatas.length - 1 !== index && (
                        <Spacing md="20" lg="20" />
                      )}
                    </>
                  ))}
              </div>
              <div className="col-lg-6 col-xl-6 cs_multi_accord_right">
                {rightSideDatas &&
                  rightSideDatas.length > 0 &&
                  rightSideDatas.map((accord, index) => (
                    <>
                      <SingleGridAccordion
                        accordionData={accord}
                        customClasses={customClasses}
                      />
                      {rightSideDatas.length - 1 !== index && (
                        <Spacing md="20" lg="20" />
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiGridAccordian;
