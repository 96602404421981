import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dietEntry: undefined,
};

const dietSlice = createSlice({
  name: "diet",
  initialState,
  reducers: {
    generateDietReport: (state, action) => {
      state.dietEntry = action.payload;
    },
  },
});

export const { generateDietReport } = dietSlice.actions;

export default dietSlice.reducer;
