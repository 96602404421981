import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import { useTranslation } from "react-i18next";
import { eLearningCourseContentDatas } from "./eLearningContents";
import DepartmentSectionStyle2 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle2";
import Section from "../../../components/Section";
import AwardSection from "../../../components/Section/AwardSection";
import FeaturesSectionStyle5 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle5";
import List from "../../../components/List";
import Spacing from "../../../components/Spacing";
import Cta from "../../../components/Cta";
import BannerSectionStyle2 from "../../../components/Section/BannerSection/BannerSectionStyle2";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";

const ELearningCourse = () => {
  pageTitle("E-Learning Course");
  const { t } = useTranslation(["eLearningCourse", "common"]);

  const eLearningCourseOffersRef = useRef(null);
  const benefitsOfELearningRef = useRef(null);
  const ckdEducationRef = useRef(null);
  const additionalFeaturesRef = useRef(null);
  const controlYourCKDRef = useRef(null);
  const disclaimerRef = useRef(null);
  const courseforCKDPatientsRef = useRef(null);
  const additionalConsiderationsRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "E-Learning course offers",
      btnRef: eLearningCourseOffersRef,
      btnImage:
        "/images/our_services/e-learning-course/e-learning-course-icon-01.svg",
    },
    {
      btnName: "Key benefits of e-learning",
      btnRef: benefitsOfELearningRef,
      btnImage:
        "/images/our_services/e-learning-course/e-learning-course-icon-02.svg",
    },
    {
      btnName: "CKD education journey",
      btnRef: ckdEducationRef,
      btnImage:
        "/images/our_services/e-learning-course/e-learning-course-icon-03.svg",
    },
    {
      btnName: "Additional features",
      btnRef: additionalFeaturesRef,
      btnImage:
        "/images/our_services/e-learning-course/e-learning-course-icon-04.svg",
    },
    {
      btnName: "Control of your CKD",
      btnRef: controlYourCKDRef,
      btnImage:
        "/images/our_services/e-learning-course/e-learning-course-icon-05.svg",
    },
    // {
    //   btnName: "Disclaimer",
    //   btnRef: disclaimerRef,
    // },
    {
      btnName: "Course for CKD patients",
      btnRef: courseforCKDPatientsRef,
      btnImage:
        "/images/our_services/e-learning-course/e-learning-course-icon-06.svg",
    },
    {
      btnName: "Additional considerations",
      btnRef: additionalConsiderationsRef,
      btnImage:
        "/images/our_services/e-learning-course/e-learning-course-icon-07.svg",
    },
  ];

  const {
    ourInteractiveELearningCourseOffersData,
    keyBenefitsOurELearningCourseData,
    additionalFeaturesData,
    eLearningCourseForCKDPatientsModule1,
    eLearningCourseForCKDPatientsModule2,
    eLearningCourseForCKDPatientsModule3,
    eLearningCourseForCKDPatientsModule4,
    eLearningCourseForCKDPatientsModule5,
    eLearningCourseForCKDPatientsModule6,
    additionalConsiderationsData,
  } = eLearningCourseContentDatas();

  const { blogData } = commonConstants();

  const disclaimerList = [
    {
      title: `${t("disclaimer.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("disclaimer.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsList1 = [
    {
      title: `${t("eLearningCourseForCKDPatients.module1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description=""
          data={eLearningCourseForCKDPatientsModule1}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsList2 = [
    {
      title: `${t("eLearningCourseForCKDPatients.module2.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description=""
          data={eLearningCourseForCKDPatientsModule2}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsList3 = [
    {
      title: `${t("eLearningCourseForCKDPatients.module3.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description=""
          data={eLearningCourseForCKDPatientsModule3}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsList4 = [
    {
      title: `${t("eLearningCourseForCKDPatients.module4.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description=""
          data={eLearningCourseForCKDPatientsModule4}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsList5 = [
    {
      title: `${t("eLearningCourseForCKDPatients.module5.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description=""
          data={eLearningCourseForCKDPatientsModule5}
        />
      ),
    },
  ];

  const eLearningCourseForCKDPatientsList6 = [
    {
      title: `${t("eLearningCourseForCKDPatients.module6.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description=""
          data={eLearningCourseForCKDPatientsModule6}
        />
      ),
    },
  ];

  const additionalConsiderationsLists = [
    {
      title: `${t("additionalConsiderations.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List heading="" description="" data={additionalConsiderationsData} />
      ),
    },
  ];

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      {/* <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/departments/banner_img.png"
        title={t("bannerContent.title")}
        subHeadTitle={t("bannerContent.subTitle")}
        subTitle={t("bannerContent.description")}
        customClasses="cs_about_kidney"
        customeThemeClass="cs_our_services_color"
      /> */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          // bgUrl="/images/home/our-service-web-banner-gradient.png"
          bgUrl="/images/our_services/e-learning-course/e-learning-course-web-banner-new.jpg"
          // imgUrl="/images/our_services/e-learning-course/e-learning-course-web-banner.jpg"
          title={t("bannerContent.title")}
          subHeadTitle={t("bannerContent.subTitle")}
          subTitle={t("bannerContent.description")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
        className="cs_white_bg"
      >
        <ScrollToSection btnLists={scrollToLists} brandName="our-services" />
      </Section>
      {/* Section Navigation End */}

      {/* E-Learning Course Offers Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling"
        sectionRef={eLearningCourseOffersRef}
      >
        <AwardSection
          sectionTitle={t("ourInteractiveELearningCourseOffers.title")}
          data={ourInteractiveELearningCourseOffersData}
        />
      </Section>
      {/* E-Learning Course Offers Section End */}

      {/* Key Benefits of Our E-Learning Course Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling"
        sectionRef={benefitsOfELearningRef}
      >
        <DepartmentSectionStyle2
          sectionTitle={t("keyBenefitsOurELearningCourse.title")}
          data={keyBenefitsOurELearningCourseData}
        />
      </Section>
      {/* Key Benefits of Our E-Learning Course Section End */}

      {/* CKD Education Journey Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_transplant"
        sectionRef={ckdEducationRef}
      >
        <Cta
          title={t("startYourCKDEducationJourney.title")}
          subTitle={t("startYourCKDEducationJourney.description")}
          bgUrl="/images/home_4/cta_bg1.jpeg"
        />
      </Section>
      {/* CKD Education Journey Section End */}

      {/* Additional Features Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_counselling"
        sectionRef={additionalFeaturesRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("additionalFeatures.title")}
          data={additionalFeaturesData}
        />
      </Section>
      {/* Additional Features Section End */}

      {/* Control of Your CKD Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg"
        sectionRef={controlYourCKDRef}
      >
        <BannerSectionStyle2
          bgUrl="/images/home_2/cta_bg1.jpeg"
          title={t("joinTheKidneyNeeds")}
          subTitle={t("enrollOurCKDELearning")}
        />
      </Section>
      {/* Control of Your CKD Section End */}

      {/* Disclaimer Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney cs_white_bg"
        sectionRef={disclaimerRef}
      >
        <div className="container">
          <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
            <SingleGridAccordion
              accordionData={disclaimerList}
              customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title cs_accordion_no_spacing"
            />
          </div>
        </div>
      </Section>
      {/* Disclaimer Section End */}

      {/* E-Learning Course for CKD Patients Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        sectionRef={courseforCKDPatientsRef}
      >
        <div className="cs_doctor_details">
          <div
            className="cs_doctor_details_bg cs_bg_filed"
            style={{
              backgroundImage: `url(/images/home/our-services-bg.svg)`,
            }}
          />
          <Spacing md="85" />
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="cs_single_doctor overflow-hidden cs_radius_20">
                  <img
                    src="/images/our_services/e-learning-course/ckd-patients-newone.jpg"
                    alt="Doctor"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 position-relative">
                <Spacing md="55" />
                <h2
                  className="cs_fs_48 mb-0 cs_semibold testimonial-title"
                  style={{ color: "#333333" }}
                >
                  {t("eLearningCourseForCKDPatients.title")}
                </h2>
                <Spacing md="32" />
                <p
                  className="mb-0 cs_heading_color testimonial-sub-title"
                  style={{ color: "#333333" }}
                >{`${t("eLearningCourseForCKDPatients.description")}`}</p>
                <Spacing md="50" xl="150" lg="100" />
                <SingleGridAccordion
                  accordionData={eLearningCourseForCKDPatientsList1}
                  customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
                />
                {/* <List
                  heading={t("eLearningCourseForCKDPatients.module1.title")}
                  iconUrl="/images/icons/graduation.svg"
                  data={eLearningCourseForCKDPatientsModule1}
                /> */}
                <Spacing md="30" lg="30" />
                <SingleGridAccordion
                  accordionData={eLearningCourseForCKDPatientsList2}
                  customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
                />
                {/* <List
                  heading={t("eLearningCourseForCKDPatients.module2.title")}
                  iconUrl="/images/icons/graduation.svg"
                  data={eLearningCourseForCKDPatientsModule2}
                /> */}
                <Spacing md="30" lg="30" />
                <SingleGridAccordion
                  accordionData={eLearningCourseForCKDPatientsList3}
                  customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
                />
                {/* <List
                  heading={t("eLearningCourseForCKDPatients.module3.title")}
                  iconUrl="/images/icons/graduation.svg"
                  data={eLearningCourseForCKDPatientsModule3}
                /> */}
                <Spacing md="30" lg="30" />
                <SingleGridAccordion
                  accordionData={eLearningCourseForCKDPatientsList4}
                  customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
                />
                {/* <List
                  heading={t("eLearningCourseForCKDPatients.module4.title")}
                  iconUrl="/images/icons/graduation.svg"
                  data={eLearningCourseForCKDPatientsModule4}
                /> */}
                <Spacing md="30" lg="30" />
                <SingleGridAccordion
                  accordionData={eLearningCourseForCKDPatientsList5}
                  customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
                />
                {/* <List
                  heading={t("eLearningCourseForCKDPatients.module5.title")}
                  iconUrl="/images/icons/graduation.svg"
                  data={eLearningCourseForCKDPatientsModule5}
                /> */}
                <Spacing md="30" lg="30" />
                <SingleGridAccordion
                  accordionData={eLearningCourseForCKDPatientsList6}
                  customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
                />
                {/* <List
                  heading={t("eLearningCourseForCKDPatients.module6.title")}
                  iconUrl="/images/icons/graduation.svg"
                  data={eLearningCourseForCKDPatientsModule6}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* E-Learning Course for CKD Patients Section End */}

      {/* Additional Considerations Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        sectionRef={additionalConsiderationsRef}
      >
        <SingleGridAccordion
          accordionData={additionalConsiderationsLists}
          customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
        />
      </Section>
      {/* Additional Considerations Section End */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection
          customeThemeClass={"bright-blogs"}
          sectionTitle=""
          sectionTitleUp=""
          data={blogData}
        />
      </Section>
    </>
  );
};

export default ELearningCourse;
