import React, { useState } from 'react';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import KidneyBreadcrumStyle from '../Breadcrumb/KidneyBreadcrumStyle';
import FaqBannerSectionStyle from '../Section/BannerSection/FaqBannerSectionStyle';
import CardFilterSection from './FaqCardFilterSection';
import "../../sass/shortcode/faq.scss"
import Spacing from '../Spacing';



export default function Faq() {
  pageTitle('Faq');
  // const { t } = useTranslation(["common"]);
  // const { blogData } = commonConstants();

  const cardData = [
    // Kidney Education cards 
    // {
    //   id: 1,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-01.jpg',
    //   title: 'Healthy Kidneys',
    //   content: 'Your kidneys are two bean-shaped powerhouses tucked away in your lower back. They silently work around the clock to perform several vital tasks, making them essential for overall health.',
    //   // url: '/our-services/counselling#faq'
    // },
    // {
    //   id: 2,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-02.jpg',
    //   title: 'Kidney Diseases',
    //   content: 'We all know our kidneys are important, but what exactly do they do? Imagine your kidneys as your bodys filtration system, constantly working to keep your blood clean and healthy. They remove waste products and excess fluids, like tiny filters.'
    // },
    // {
    //   id: 3,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-03.jpg',
    //   title: 'Dialysis',
    //   content: 'For individuals diagnosed with chronic kidney disease (CKD) and whose kidneys can no longer effectively filter waste products and excess fluids from the blood, dialysis becomes a vital treatment option.'
    // },
    // {
    //   id: 4,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-04.jpg',
    //   title: 'Transplant',
    //   content: 'A kidney transplant offers a potential path to renewed health and freedom from dialysis. This section explores kidney transplantation, its benefits, and the evaluation process.'
    // },
    // {
    //   id: 5,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-05.jpg',
    //   title: 'Kidney Biopsy',
    //   content: 'If youve been experiencing concerning symptoms or abnormal test results related to your kidneys, your doctor might recommend a kidney biopsy. This procedure involves taking a small tissue sample from your kidney to examine it under a microscope.'
    // },
    // {
    //   id: 6,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-06.jpg',
    //   title: 'AV Fistula',
    //   content: 'Your Pathway to a More Empowered Dialysis Experience For individuals with chronic kidney disease (CKD) facing the prospect of hemodialysis, a critical decision arises: choosing the most suitable vascular access for treatment'
    // },
    // {
    //   id: 7,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-07.jpg',
    //   title: 'Dialysis Catheters',
    //   content: 'For chronic kidney disease (CKD) patients requiring hemodialysis, understanding vascular access options is critical. Dialysis catheters are one way to allow doctors to remove waste products and excess fluids from the blood.'
    // },
    // {
    //   id: 8,
    //   category: 'Kidney Education',
    //   image: '/images/Faq/Kidney-Education-500x500-08.jpg',
    //   title: 'Medicines',
    //   content: 'CKD and high blood pressure (hypertension) can sometimes go hand-in-hand. While high blood pressure can put additional strain on your kidneys, the good news is there are powerful tools available to help you manage both conditions effectively.'
    // },


    // Our Services cards 
    {
      id: 11,
      category: 'Our Services',
      image: '/images/Faq/Our-Services-500x500-01.jpg',
      title: 'Counselling Services',
      content: 'Are you or a loved one facing the challenges of kidney disease? You dont have to navigate this journey alone. Kidney Needs is here to provide personalized care and expert guidance, empowering you to live your best life.',
      url: '/our-services/counselling#faq'
    },
    {
      id: 12, category:
        'Our Services',
      image: '/images/Faq/Our-Services-500x500-02.jpg',
      title: 'Medicines',
      content: 'At KidneyNeeds, we understand the crucial role medications play in managing kidney health. We offer a complete solution, ensuring you have access to the right medications at the right price, with expert guidance every step of the way.',
      url: '/our-services/medicines#faq'
    },
    {
      id: 13,
      category: 'Our Services',
      image: '/images/Faq/Our-Services-500x500-03.jpg',
      title: 'Catheters',
      content: 'At KidneyNeeds, we understand the importance of choosing the right catheter for your dialysis treatment. Thats why we offer a comprehensive selection of high-quality catheters, expert guidance, and comprehensive care solutions, safety, and well-being throughout your dialysis journey.',
      url: '/our-services/catheters#faq'
    },
    {
      id: 14,
      category: 'Our Services',
      image: '/images/Faq/Our-Services-500x500-04.jpg',
      title: 'Dialysis Accessories',
      content: 'Your Comprehensive Source for Dialysis Supplies KidneyNeeds: Your One-Stop Shop for Dialysis Accessories',
      url: '/our-services/dialysis-accessories#faq'
    },
    {
      id: 15,
      category: 'Our Services',
      image: '/images/Faq/Our-Services-500x500-05.jpg',
      title: 'kidney Biopsy Gun',
      content: 'At KidneyNeeds, we understand that undergoing a biopsy can be a stressful experience and the importance of accurate and reliable tissue sampling for diagnostic procedures. Were committed to providing the highest quality biopsy instruments and information to help alleviate your concerns and ensure a smooth procedure.',
      url: '/our-services/kidney-biopsy-gun#faq'
    },
    // {
    //   id: 16,
    //   category: 'Our Services',
    //   image: '/images/Faq/Our-Services-500x500-06.jpg',
    //   title: 'E-Learning',
    //   content: 'At Kidney Needs, we understand that navigating CKD can be overwhelming. Thats why weve developed a patient-centered e-learning course designed to empower you with the knowledge and tools you need to manage your condition effectively and live a fulfilling life.',
    //   // url: '/our-services/e-learning#faq'
    // },
    {
      id: 17,
      category: 'Our Services',
      image: '/images/Faq/Our-Services-500x500-07.jpg',
      title: 'Lab Test Services',
      content: 'At KidneyNeeds, we simplify your kidney care journey. We offer a wide range of specialized lab and imaging services, all designed to provide you with accurate diagnoses, personalized treatment plans, and ongoing support at affordable prices.',
      url: '/our-services/lab-test-service#faq'
    },
    // {
    //   id: 18,
    //   category: 'Our Services',
    //   image: '/images/Faq/Our-Services-500x500-08.jpg',
    //   title: 'Health Records',
    //   content: 'At KidneyNeeds, we understand the importance of comprehensive health record management for chronic kidney disease (CKD) patients. Managing your CKD effectively requires careful monitoring of various health indicators and treatment details.',
    //   // url: '/our-services/health-records#faq'
    // },
    // {
    //   id: 19,
    //   category: 'Our Services',
    //   image: '/images/Faq/Our-Services-500x500-09.jpg',
    //   title: 'Kidney Diet',
    //   content: 'At KidneyNeeds, we understand that managing CKD can involve dietary adjustments. However, a healthy diet doesnt have to be bland or restrictive. We believe in creating delicious possibilities that empower you to make informed dietary choices and feel your best.',
    //   // url: '/our-services/kidney-diet#faq'
    // },
    // ... Add 8 more Our Services cards

    // Diet cards 
    // {
    //   id: 21,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-01.jpg',
    //   title: 'Collaborative Consultation',
    //   content: 'Our registered dietitians will schedule a personalized consultation to discuss your current dietary habits, medical history, cultural background, and lifestyle.',
    //   socialLinks: {
    //     instagram: "link-to-instagram",
    //     facebook: "link-to-facebook",
    //     twitter: "link-to-twitter"
    //   }
    // },
    // {
    //   id: 22,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-02.jpg',
    //   title: 'Medical Conditions',
    //   content: 'We will work with your doctor to understand any existing medical conditions that might influence your dietary needs. This ensures a holistic approach to your CKD management plan.'
    // },
    // {
    //   id: 23,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-03.jpg',
    //   title: 'Understanding Nutrients',
    //   content: 'We break down the science of nutrition, explaining the role of various nutrients like protein, carbohydrates, fats, vitamins, and minerals in kidney health.'
    // },
    // {
    //   id: 24,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-04.jpg',
    //   title: 'Food Choices',
    //   content: 'We provide guidance on selecting kidney-friendly foods rich in essential nutrients and discuss healthy alternatives for foods that might need limitation.'
    // },
    // {
    //   id: 25,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-05.jpg',
    //   title: 'Mindful Eating Techniques',
    //   content: 'We can guide you on incorporating mindfulness practices into your meals, helping you develop a healthier relationship with food and manage portion control.'
    // },
    // {
    //   id: 26,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-06.jpg',
    //   title: 'Exercise Guidance',
    //   content: 'We emphasize the importance of a healthy lifestyle that includes regular exercise. We can provide guidance on incorporating physical activity that complements your dietary plan and overall health goals.'
    // },
    // {
    //   id: 27,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-07.jpg',
    //   title: 'Building a Support System',
    //   content: 'We can connect you with resources and support groups to help you navigate the emotional aspects of living with CKD.'
    // },
    // {
    //   id: 28,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-08.jpg',
    //   title: 'Flexibility and Adjustments',
    //   content: 'We understand that life can be unpredictable. Our dietitians are here to support you with ongoing guidance and adjustments to your plan as needed, ensuring it remains realistic and sustainable for your lifestyle.'
    // },
    // {
    //   id: 29,
    //   category: 'Diet',
    //   image: '/images/Faq/Diet-Services-500x500-09.jpg',
    //   title: 'Ongoing Communication',
    //   content: 'Our registered dietitians are available for ongoing communication and support. You can schedule follow-up consultations to discuss any questions, concerns, or adjustments needed to your personalized diet plan.'
    // },
  ];

  const [filter, setFilter] = useState(null);

  const handleFilterChange = (category) => {
    setFilter((prevFilter) => (prevFilter === category ? null : category));
  };

  const filteredCards = filter ? cardData.filter(card => card.category === filter) : cardData;

  return (
    <>
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* <Spacing lg="600" md="120" /> */}
      <Section className="cs_banner_styles">
        <FaqBannerSectionStyle
          bgUrl="/images/Faq/FAQ-Page-1920x700.png"
          subHeadTitle={
            <>
              Frequently <br />
              Asked <br />
              Questions
            </>
          }
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <div className="faqfilter-section">
          {/* Filter Section with Title and Buttons */}
          <div className="filter-header">
            <h3 className="filter-title">Filter by:</h3>
            <div className="filter-buttons">
              <button
                onClick={() => handleFilterChange('Kidney Education')}
                className={`button kidney-education ${filter === 'Kidney Education' ? 'active' : ''}`}
              >
                Kidney Education
              </button>
              <button
                onClick={() => handleFilterChange('Our Services')}
                className={`button our-services ${filter === 'Our Services' ? 'active' : ''}`}
              >
                Our Services
              </button>
              <button
                onClick={() => handleFilterChange('Diet')}
                className={`button diet ${filter === 'Diet' ? 'active' : ''}`}
              >
                Diet
              </button>
              <button
                onClick={() => handleFilterChange('Health Record')}
                className={`button dialysis-center ${filter === 'Health Record' ? 'active' : ''}`}
              >
                Dialysis Center
              </button>
              <button
                onClick={() => handleFilterChange('Health Record')}
                className={`button health-record ${filter === 'Health Record' ? 'active' : ''}`}
              >
                Health Record
              </button>
            </div>
          </div>
          <Spacing lg="600" md="50" />
          {/* Pass only the filtered cards to the CardFilterSection */}
          <CardFilterSection cards={filteredCards} />
        </div>
      </Section>
    </>
  );
}
