import React from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
// import Post from "../../Post";
import DownloadPost from "../../Post/DownloadPost";
export default function DownloadBlogSection({
  data,
  sectionTitle,
  sectionTitleUp,
  sectionTitleDown,
  sectionSubTitle,
  customeThemeClass,
}) {
  const colorGradient = [
    { a: "#d3d3d3", b: "#a9a9a9" },
  { a: "#d3d3d3", b: "#a9a9a9" },
  { a: "#d3d3d3", b: "#a9a9a9" },
  { a: "#d3d3d3", b: "#a9a9a9" },
  ];

  return (
    <div className={`container ${customeThemeClass || ""}`}>
      {(sectionTitle || sectionTitleUp || sectionTitleDown || sectionSubTitle) && (
        <>
          <SectionHeading
            title={sectionTitle}
            titleUp={sectionTitleUp}
            titleDown={sectionTitleDown}
            subTitle={sectionSubTitle}
            center
          />
          <Spacing md="72" lg="50" />
        </>
      )}

      <div className="row gy-4">
        {data?.map((item, index) => (
          <div className="col-lg-3" key={index}>
            <DownloadPost
              {...item}
              dowbtnText={item.dowbtnText}
              btnArrowUrl={item.btnArrowUrl}
              gradient={colorGradient[index % colorGradient.length]}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
