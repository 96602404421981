import React from "react";
import ImageWithTextOverlay from "./ImageWithTextOverlay";

const ImageWithTextOverlaySection = ({ imageWithTextData = [], showTint }) => {
  return (
    <div className="container">
      <div className="row gy-4">
        {imageWithTextData?.map((item, index) => (
          <div className="col-xl-3 col-lg-6 col-md-6" key={index}>
            <ImageWithTextOverlay {...item} showTint={showTint} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageWithTextOverlaySection;
