import React from "react";

export default function FeaturesSectionStyle6({
  sectionTitle,
  image1,
  image2,

}) {
  return (
  
      <div className="container">
        <div className="cs_section_heading cs_style_1 text-center">
          <h2 className="cs_section_title cs_fs_72 m-0">{sectionTitle}</h2>
          
        </div>
        <div className="cs_image_grid">
        <img src={image1} alt="Feature 1" className="cs_image" />
        <img src={image2} alt="Feature 2" className="cs_image" />
      </div>
        {/* <div className="cs_image_section">
        <img src={image1} alt="Feature Image 1" className="cs_image_1" />
      </div> */}
        {/* <div className="cs_height_72 cs_height_lg_50" /> */}
     
     </div>
  );
}
